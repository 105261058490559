import { JacidiAppLink, JacidiAppLinkKey } from '@components/jacidi-components-lib';

export const AVAILABLE_APPS: JacidiAppLink[] = [
  {
    key: JacidiAppLinkKey.Cartas,
    name: 'Cartas',
    href: '',
  },
  {
    key: JacidiAppLinkKey.Signage,
    name: 'Signage',
    href: 'https://signage.ticepro.com/',
  },
];
